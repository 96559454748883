<template>
  <div v-if="params != false">
    <b-row class="justify-content-md-center">
      <b-col md="9">
        <b-card>
          <div class="d-block text-right">
            <b-button variant="primary" class="btn-icon" size="sm" @click="add()"><i class="fa fa-plus"></i></b-button>
          </div>
          <b-table-simple hover responsive>    
            <b-thead>              
              <b-tr>
                <b-th>ID</b-th>
                <b-th>Titre</b-th>
                <b-th>Action</b-th>                
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="(p, i) in params" :key="i">                
                <b-td>{{ p.id }}</b-td>
                <b-td>
                  <b-form-input v-model="p.titre.fr"></b-form-input>
                </b-td>
                <b-td>
                  <b-button variant="success" class="btn-icon" size="sm" @click="save(p)"><i class="fa fa-save"></i></b-button>
                </b-td>         
              </b-tr>      
            </b-tbody>    
          </b-table-simple>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "MagicListe",
  components: {    
  },
  data(){
    return {  
      params: false    
    }
  },
  computed:{    
    query(){
      return `?module=${this.$route.query.model}`;      
    }
  },
  watch:{    
  },
  mounted(){  
    this.init();  
  },
  methods: {  
    init(){
      this.$store.back.ajax(`/magic/liste${this.query}`, null, res => {
        this.params = res.data;
      })
    },
    add(){
      this.params.unshift({
        id:null,
        titre:{
          fr:null
        }
      })
    },
    save(p){
      this.$store.back.ajax(`/magic/create${this.query}`, p, res => {
        if(res.status){
          this.init();
          this.$bvToast.toast(`Parametre`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
        }
      });
    }
  }
}
</script>
<style>
table {
  table-layout: fixed !important;
}
</style>
